// SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => (
  <Helmet>
    <title>what is my ip number - See Your Public Address - IPv4 & IPv6</title>
    <meta name="description" content="Find out what your public IPv4 and IPv6 address is revealing about you! My IP address information shows your IP location; city, region, country, ISP, Pin Number and location ." />
    
    <meta name="keywords" content=" what is my ip number, what is my ip no, myipview, my ip view, view my ip,  track my ip, what şs my ip, ip whats my ip,what's it my ip,what's my internet address,what i smy ip address
    what is my ip adresa,what is myip address,what is myipaddress,ip what is my ip,my what is my ip,what myip address,what's my ip add,what's your ip address
     " />
    <link rel="canonical" href="https://www.myipview.com/" />
    <meta property="og:title" content="what's my ip - See Your Public Address - IPv4 & IPv6" />
    <meta property="og:description" content="Find out what your public IPv4 and IPv6 address is revealing about you! My IP address information shows your IP location; city, region, country, ISP, Pin Number and location ." />
    <meta property="og:url" content="https://www.myipview.com/" />
    <meta property="og:site_name" content="My IP View" />
    <meta property="og:image" content="%PUBLIC_URL%/favicon-16x16.png" />
  </Helmet>
);

export default SEO;
