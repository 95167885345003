// IpInfo.js
import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { getCountryName } from './countryCodes';
import useIsSmallScreen from './useIsSmallScreen';
import './App.css';

function IpInfo({ ipInfo }) {
  const isSmallScreen = useIsSmallScreen();

  return (
    <Box className="ip-info">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className="ip-info-row">
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-label">ISP:</Typography>
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-data">{ipInfo.org}</Typography>
          </div>
          <div className="ip-info-row">
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-label">City:</Typography>
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-data">{ipInfo.city}</Typography>
          </div>
          <div className="ip-info-row">
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-label">Region:</Typography>
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-data">{ipInfo.region}</Typography>
          </div>
          <div className="ip-info-row">
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-label">Country:</Typography>
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-data">{getCountryName(ipInfo.country)}</Typography>
          </div>
          <div className="ip-info-row">
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-label">Location:</Typography>
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-data">{ipInfo.loc}</Typography>
          </div>
          <div className="ip-info-row">
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-label">Postal:</Typography>
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-data">{ipInfo.postal}</Typography>
          </div>
          <div className="ip-info-row">
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-label">Timezone:</Typography>
            <Typography variant={isSmallScreen ? "h9" : "h5"} className="ip-info-data">{ipInfo.timezone}</Typography>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default IpInfo;
