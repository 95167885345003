// AppBar.js
import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import './AppBar.css';

function CustomAppBar() {
  return (
    <AppBar position="fixed" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar>
        <div className="logo">
          Myipview
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default CustomAppBar;
