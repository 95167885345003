// ErrorPage.js
import React from 'react';
import { Typography } from '@mui/material';

function ErrorPage({ error }) {
  return (
    <div>
      <Typography variant="h1">An error occurred</Typography>
      <Typography variant="body1">{error}</Typography>
    </div>
  );
}

export default ErrorPage;
