// App.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, CircularProgress, Grid, Button, useMediaQuery, Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CustomAppBar from './AppBar';
import { handleReload } from './reload';
import { handleBookmark } from './bookmark';
import Footer from './Footer';
import './App.css';
import IpInfo from './IpInfo';
import ErrorPage from './ErrorPage';
import { fetchIpInfo } from './fetchIpInfo';
import { fetchIpv6Info } from './fetchIpv6Info';
import SEO from './SEO';
import ReactGA from "react-ga4";
import FAQ from './FAQ'; // import the FAQ component

ReactGA.initialize("G-CCFW9D78X3");


function App() {

  const [ipInfo, setIpInfo] = useState({});
  const [ipv6Info, setIpv6Info] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const fetchData = async () => {
    try {
      const [ipInfo, ipv6Info] = await Promise.all([fetchIpInfo(), fetchIpv6Info()]);
      setIpInfo(ipInfo);
      setIpv6Info(ipv6Info);
    } catch (error) {
      console.error('Error fetching IP information:', error);
      setError(`Error fetching IP information: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopy = (text) => {
    try {
      navigator.clipboard.writeText(text);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <Box className="app">
       <SEO />
      <CustomAppBar />
      <Container className="container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography variant={isSmallScreen ? "h4" : "h1"} gutterBottom>
              What is My IP Number ?
            </Typography>
            <Typography variant={isSmallScreen ? "h9" : (isMediumScreen ? "h8" : "h6")} gutterBottom>
            My Public IPv4:  {ipInfo.ip} <FileCopyIcon className="copy-btn animated-icon" onClick={() => handleCopy(ipInfo.ip)} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={isSmallScreen ? "h9" : (isMediumScreen ? "h8" : "h6")} gutterBottom>
            My Public IPv6:  {ipv6Info.ip} <FileCopyIcon className="copy-btn animated-icon" onClick={() => handleCopy(ipv6Info.ip)} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={isSmallScreen ? "h6" : (isMediumScreen ? "h5" : "h4")} gutterBottom>{ipInfo.city} / {ipInfo.country}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button id="reload-button" onClick={handleReload}>Reload</Button>
            <Button id="bookmark-button" onClick={handleBookmark}>Add to Bookmark</Button>
          </Grid>
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <IpInfo ipInfo={ipInfo} />
        )}
       <FAQ /> {/* Use the FAQ component */}
      </Container>
      <Footer />
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          message={error}
          action={
            <Button color="secondary" size="small" onClick={() => setError(null)}>
              Close
            </Button>
          }
        />
      )}
    </Box>
  );
}

export default App;
