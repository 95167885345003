// Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box className="footer" style={{ backgroundColor: '#101820', padding: '20px 0' }}>
      <Typography variant="body2" style={{ fontSize: '14px' }}>©2024 Myipview. All rights reserved.</Typography>
    </Box>
  );
}

export default Footer;
