// fetchIpInfo.js
import axios from 'axios';

export const fetchIpInfo = async () => {
  const maxRetries = 3;
  let retries = 0;
  while (retries < maxRetries) {
    try {
      const response = await axios('https://ipinfo.io/json');
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error fetching IPv4 information:', error);
      retries++;
      const delay = Math.pow(2, retries) * 1000; // exponential backoff
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  throw new Error('Max retries exceeded');
};