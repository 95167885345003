// FAQ.js
import React from 'react';
import { Typography, Box } from '@mui/material';

function FAQ() {
  return (
    <Box className="qa-section">
      <Typography variant='h6' className='question'>
       <h2> What is an IP Address? </h2>
      </Typography>
      <Typography variant='body1' className='answer'>
      <p>An Internet Protocol (IP) address is a unique numerical label assigned to each device participating in a computer network that uses the Internet Protocol for communication. It serves two main functions: identifying the host or network interface, and providing the location of the host in the network.</p>
    <p>There are two versions of IP addresses in use today - IPv4 and IPv6. IPv4, the most common form, consists of four sets of numbers from 0 to 255, separated by periods. For example, “192.168.1.1”. However, due to the increasing number of devices worldwide, IPv4 addresses are running out. Hence, IPv6, which consists of eight groups of four hexadecimal digits, was introduced to provide more address space.</p>
    <p>IP addresses can be either static or dynamic. Static IP addresses remain constant, while dynamic IP addresses change every time a device connects to the internet. Most home networks use dynamic IP addresses.</p>
      </Typography>
      <Typography variant="h6" className="question">
        <h2>What is IPv4?</h2>
      </Typography>
      <Typography variant="body1" className="answer">
        <p>In the digital world, Internet Protocol version 4 (IPv4) is a term that often pops up. But what exactly is IPv4? Let’s dive in and unravel this mystery.</p>
        <p>IPv4 is the fourth version of the Internet Protocol (IP), the underlying technology that makes our internet work. It’s a set of rules that govern how data is sent and received over networks.</p>
        <p>Each device connected to the internet, be it your smartphone, laptop, or smart TV, is assigned a unique IPv4 address. This address, composed of four sets of numbers separated by periods (e.g., 192.168.1.1), serves as the device’s identity on the internet. It’s like your home address, guiding the data packets to the right destination.</p>
      </Typography>

      <Typography variant="h6" className="question">
        <h2>What is IPv6?</h2>
      </Typography>
      <Typography variant="body1" className="answer">
        <p>IPv6, or Internet Protocol version 6, is like a new and improved app for the internet. It’s the successor to IPv4, which was running out of unique addresses for all the devices connecting to the internet. IPv6 solves this problem by providing a massive number of unique addresses.</p>
      <p>An IPv6 address looks different from an IPv4 address. It’s longer and has eight parts, not four. Each part has four characters, like this: 2001:0db8:85a3:0000:0000:8a2e:0370:7334.</p>
      <p>ut IPv6 isn’t just about more addresses. It also improves the internet’s functionality. For instance, it enhances data transmission and simplifies how devices connect to the internet. Plus, it has built-in security features that IPv4 lacks.</p>
      <p>So, in simple words, IPv6 is all about upgrading the internet and preparing for the future.</p>
      </Typography>

      <Typography variant="h6" className="question">
        <h2>What is an ISP?</h2>
      </Typography>
      <Typography variant="body1" className="answer">
        ISP stands for Internet Service Provider. It's a company that provides you with access to the Internet. Examples include Comcast, AT&T, and Verizon.
      </Typography>

      <Typography variant="h6" className="question">
        <h2>What is a VPN?</h2>
      </Typography>
      <Typography variant="body1" className="answer">
        <p>A VPN, or Virtual Private Network, is a tool that helps protect your online privacy. It creates a secure connection between your device and the internet. When you use a VPN, your data travels through an encrypted tunnel, keeping it safe from prying eyes. This means that your internet service provider, or any hackers, can’t see what websites you visit or what information you send and receive online. A VPN can also hide your IP address, making it harder for others to track your online activities. Plus, it can help you access websites that are blocked in your region. So, a VPN is a great tool for maintaining online privacy and freedom.</p>
      </Typography>
    </Box>
  );
}

export default FAQ;
